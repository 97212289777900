<template>
	<div>
		<div style="margin: 30px auto 30px auto;width: 180px" v-if="dc_qrcode">
			<van-image :src="dc_qrcode" width="180px" />

			<p style="text-align: center;font-size: 12px;color: #999">收款二维码</p>
		</div>

		<div style="background: #fff;padding: 16px" v-if="dc_address">
			<div class="deposit-block-value">
				<p>收款地址</p>
				<p style="font-size: 16px;margin-top: 10px"><b>{{ dc_address }}</b></p>
			</div>

			<div class="deposit-block-function" @click="copy(dc_address)"><van-icon name="orders-o" size="21"/></div>

			<div style="clear:both"></div>
		</div>

		<h4 class="h4_title">入金信息</h4>
		<van-cell-group>
			<van-cell title="入金金额" :value="total" />
			<van-cell title="到账金额" :value="received_total" v-if="status == 2"/>
			<van-cell title="入金方式" :value="method" />
			<van-cell title="当前状态">
				<template #right-icon>
					<span style="color: grey;" v-if="status == 1">待转账</span>
					<span style="color: steelblue;" v-if="status == 2">已成功</span>
					<span style="color: red;" v-if="status == 3">已取消</span>
					<span style="color: blue;" v-if="status == 4">已上传回单</span>
				</template>
			</van-cell>
			<van-cell title="取消原因" :value="reason" v-show="reason" />
			<van-cell title="提交日期" :value="date" />
		</van-cell-group>

		<h4 class="h4_title">回单上传</h4>
		
		<div style="text-align: center;background-color: #FFFFFF;padding: 10px 0;">
			<van-image width="140" height="140" :src="image" style="vertical-align: middle;" @click="imageUpload()"/>
		</div>
		
	</div>
</template>

<script>
	export default {
		name: 'deposit_info',
		data() {
			return {
				formItem: {
					id: "",
					token: "",
					image: ""
				},
				
				total: "",
				received_total: "",
				amount: "",
				method: "",
				date: "",
				status: "",
				reason: "",
				dc_address: "",
				dc_qrcode: "",

				api_call: 1,
				image: ""
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token
				this.formItem.id = this.$route.query.id
				
				this.loadData()
			}
			
			window.set_pic = this.set_pic
		},
		methods: {
			/*   
             * 复制数据
             */
			copy(data){
                if (window.JsObject != null) {
                    window.JsObject.copyDepositData(data);
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"copy_deposit_data": data});
                }
			},
			loadData() {
				this.$toast.loading({
					message: '加载中...',
					forbidClick: true,
				})
				
				this.$axios({
					method: 'post',
					url: 'cash_deposit/info', 
					data: this.formItem
				}).then ((res) => {
					
					this.$toast.clear()
					
					if (res.success) {
						this.total = res.data.total
						this.received_total = res.data.received_total
						this.amount = res.data.amount
						this.method = res.data.method
						this.date = res.data.date
						this.status = res.data.status
						this.image = res.data.image
						this.reason = res.data.reason

						this.dc_address = res.data.dc_address
						this.dc_qrcode = res.data.dc_qrcode

						this.api_call = res.data.api_call
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			imageUpload() {
				if (this.status == '1') {
					if (window.JsObject != null) {
						window.JsObject.normalImageUpload();
					}
					
					if (window.webkit != null) {
						window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "normal"});
					}
				}
			},
			set_pic(path) {
				this.formItem.image = path
				
				this.$axios({
					method: 'post',
					url: 'cash_deposit/resize', 
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.image = res.data.preview
						
						this.$toast.success('上传成功，请等待财务确认');
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			}
		}
	}
</script>